<template>
  <section class="bg-gray-200 py-16">
      <div class="container mx-auto px-5">
        <h3 class="uppercase text-center text-2xl font-medium tracking-wider">{{data.title}}</h3>
        <div class="bg-cyan h-1 w-20 mt-2 mx-auto mb-12"></div>
        <div class="grid grid-cols-2 md:grid-cols-3 w-11/12 md:w-4/5 lg:w-2/3 mx-auto gap-10 md:gap-16">
            <div v-for="(value, idx) in data.list" :key="idx">
                <img :src="value" alt="" class="w-full h-20 object-contain">
            </div>
        </div>          
      </div>
  </section>
</template>

<script>

import services from '@/data/services.js'

export default {
    data() {
        return {
            data: services.client
        }
    }
}
</script>

<style>

</style>