<template>
  <section class="container mx-auto px-5">

    <h3 class="uppercase text-xl md:text-2xl font-medium tracking-wider text-center mx-auto mt-20">Contact Us</h3>
    <div class="bg-cyan h-1 w-20 mt-2 mb-10 mx-auto"></div>

      <div class="bg-white md:w-4/5 mx-auto p-12">
        <h3 class="text-center md:w-5/6 mx-auto mb-10">
            Whether you have a question about services, products & application, or need our products.
            Our team is ready to answer all your questions
        </h3>
        <div class="line-cus mb-5"></div>
        <div>
            <div v-if="this.notice != ''" class="bg-blue-100 text-center my-10 py-5 px-5 rounded">
                <span class="text-blue-800">There was a problem submitting your message,</span>
                <span class="text-blue-800">{{ notice }}</span>  
            </div>   
            <form action="" class="space-y-6" @submit.prevent="handleSubmit">

                <!-- full name -->
                <div class="flex flex-col">
                    <label class="mb-3">Full Name</label>
                    <input required type="text" name="name" id="" class="border p-2 border-gray-300" v-model="theForm.name">
                </div>

                <!-- company name -->
                <div class="flex flex-col">
                    <label class="mb-3">Company Name</label>
                    <input required type="text" name="company" id="" class="border p-2 border-gray-300" v-model="theForm.company">
                </div>

                <!-- email address -->
                <div class="flex flex-col">
                    <label class="mb-3">Email Address</label>
                    <input required type="text" name="email" id="" class="border p-2 border-gray-300" v-model="theForm.email">
                </div>

                <!-- phone number -->
                <div class="flex flex-col">
                    <label class="mb-3">Phone Number</label>
                    <input type="tel" pattern="^[0-9-+\s()]*$" required name="phone" id="" class="border p-2 border-gray-300" v-model="theForm.phone">
                </div>

                <!-- inquiry needs -->
                <div class="flex flex-col">
                    <label class="mb-3">Inquiry / Needs</label>
                    <input required type="text" name="inquiry" id="" class="border p-2 border-gray-300" v-model="theForm.inquiry">
                </div>

                <button :disabled="setting.btnDisable" type="submit" class="bg-blue-500 flex px-10 py-2 rounded-md items-center justify-center mx-auto">
                    <span class="text-white text-2xl uppercase tracking-widest">{{ setting.btnText }}</span>
                    <img src="@/assets/products/mail.svg" alt="" class="w-5 ml-3">
                </button>
            </form>

          <!-- Successfull send contact -->
          <div v-if="setting.success" class="bg-green-100 py-3 px-5 mt-12 rounded text-center ">
            <span class="text-green-700">Successful Send the Contact Form :)</span><br/>
            <span class="text-green-700">We will contact you as soon as we check the form</span>
          </div>

          <!-- failed send contact -->
          <div v-if="setting.failed" class="bg-red-100 py-3 px-5 mt-12 rounded text-center text-red-700">
            Failed to Send Data, Please Try Again or Contact Us by Phone / Mail
          </div>

        </div>
      </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            theForm: {
                name: "",
                company: "",
                email: "",
                phone: "",
                inquiry: ""
            },
            notice: "",
            setting: {
                btnText: 'Submit',
                btnDisable: false,
                form: true,
                success: false,
                failed: false
            }
        }
    },
    methods: {
        handleSubmit() {
            const url = "https://suryalanggengindonesia.com/.netlify/functions/contact"

            if (!this.validEmail(this.theForm.email)) {
                return (this.notice = "The email address is badly formatted.");
            } 

            this.setting.btnText = "Please Wait";
            this.setting.btnDisable = true;
            this.notice = "";

            axios.post(url, this.theForm)
                    .then(() => {
                        this.setting.form = false;
                        this.setting.success = true;
                    })
                    .catch(() => {
                        this.setting.form = false;
                        this.setting.failed = true;
                    })
                    .finally(() => {
                        this.setting.btnDisable = false;
                        this.setting.btnText = "Submit";
                })
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
    }
}
</script>

<style lang="scss" scoped>
.line-cus {
    // background: rgb(255,255,255);
    background: rgb(234,238,243);
    background: linear-gradient(90deg, rgba(234,238,243,0) 0%, rgba(210,214,218,1) 25%, rgba(210,214,218,1) 75%, rgba(234,238,243,0) 100%);
    height: 1px;
}
</style>