<template>
  <section class="container mx-auto px-5">
      <div class="grid grid-serv gap-14">
          <div>
            <h3 class="uppercase text-2xl font-medium tracking-wider">{{data.name()}}</h3>
            <div class="bg-cyan h-1 w-20 mt-2 mb-5"></div>
            <h2 class="text-3xl font-semibold mb-5">{{data.title}}</h2>
            <p class="mb-5">{{data.content}}</p>
            <div class="space-y-4">
                <div class="flex items-start" v-for="(value, idx) in data.list" :key="idx">
                    <img :src="data.icon" alt="" class="mr-3">
                    <p>{{value}}</p>
                </div>
            </div>
          </div>
          <div class="my-auto">
              <img :src="data.image" alt="" class="w-9/12 lg:w-full mx-auto">
          </div>
      </div>
  </section>
</template>

<script>
import services from '@/data/services.js'
export default {
    data() {
        return {
            data: services.services
        }
    }
}
</script>



<style lang="scss" scoped>
    .grid-serv {
        grid-template-columns: 1fr;
    }

@media only screen and (min-width: 768px) {
    .grid-serv {
        grid-template-columns: 4fr 2.5fr;
    }
}
</style>