<template>
  <section class="container mx-auto px-5">

    <h3 ref="list-product-application"  class="uppercase text-xl md:text-2xl font-medium tracking-wider text-center mx-auto pt-20">{{data.name.title}}</h3>
    <div class="bg-cyan h-1 w-20 mt-2 mb-12 mx-auto"></div>

      <!-- CLEANER -->
      <div :ref="data.product1.linkId" class="py-2">
          <div @click="updateStatus(1)">
              <title-product :data="data.product1.headline" class="cursor-pointer"></title-product>
            </div>
          <div class="w-11/12 mx-auto bg-white py-4 mb-10" v-if="data.product1.status">
                <!-- CLEANER -->
              <div class="w-11/12 mx-auto py-7">
                  <h3 class="text-center text-3xl font-semibold mb-5">{{data.product1.p1.title}}</h3>
                   <div class="line-cus mb-5"></div>  
                  <p class="mb-8">{{data.product1.p1.content}}</p>
                  <div class="flex justify-center">
                      <router-link to="/contact">
                        <span class="border-cyan border-2 px-5 py-1.5 rounded-full inline-block text-center">
                            For more detail and spesific product, please contact us
                        </span>
                      </router-link>
                    </div>  
              </div>

              <!-- STRIPPER -->
                <div class="w-11/12 mx-auto py-7">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product1.p2.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <p class="mb-8">{{data.product1.p2.content}}</p>
                    <div class="flex justify-center">
                      <router-link to="/contact">
                        <span class="border-cyan border-2 px-5 py-1.5 rounded-full inline-block text-center">
                            For more detail and spesific product, please contact us
                        </span>
                      </router-link>
                    </div>  
                </div>

               <!-- PRODUCT -->
                <div class="w-11/12 mx-auto py-7">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product1.products.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-2 lg:w-7/12 mx-auto">
                        <div v-for="(value, idx) in data.product1.products.list" :key="idx" class="flex mb-3">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-6">
                            <p class="font-semibold">{{value}}</p>
                        </div>
                    </div>    
                </div>
            </div>    
      </div>

      <!-- HARDCHROME   -->
        <div :ref="data.product2.linkId" class="py-2">
          <div @click="updateStatus(2)">
              <title-product :data="data.product2.headline" class="cursor-pointer"></title-product>
            </div>
          <div class="w-11/12 mx-auto bg-white py-4 mb-10" v-if="data.product2.status">
              <div class="w-11/12 mx-auto py-6">
                  <h3 class="text-center text-3xl font-semibold mb-5">{{data.product2.p1.title}}</h3>
                   <div class="line-cus mb-5"></div>  
                  <div class="grid grid-cols-1 lg:grid-cols-2">
                      <p class="mb-8 mr-10" v-html="data.product2.p1.content"></p>
                      <img :src="data.product2.p1.image" alt="" class="w-9/12 m-auto">
                  </div>
              </div>

                <div class="w-11/12 mx-auto py-6">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product2.p2.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-1 lg:grid-cols-2">
                        <p class="mb-8 mr-10" v-html="data.product2.p2.content"></p>
                        <img :src="data.product2.p2.image" alt="" class="w-9/12 m-auto">
                    </div>
                </div>

                <div class="w-11/12 mx-auto py-6">
                    <h3 class="text-3xl font-semibold mb-2">{{data.product2.p3.title}}</h3>
                    <div class="bg-cyan h-1 w-20"></div>  
                    <div class="grid grid-cols-1 mt-5">
                        <div v-for="(value, idx) in data.product2.p3.list" :key="idx" class="flex mb-3">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-6">
                            <p class="font-semibold">{{value}}</p>
                        </div>
                    </div>    
                </div>

                <div class="w-11/12 mx-auto py-5">
                  <h3 class="text-center text-3xl font-semibold mb-5">{{data.product2.p4.title}}</h3>
                   <div class="line-cus mb-5"></div>  
                  <p class="mb-8" v-html="data.product2.p4.content"></p> 
              </div>

                <div class="w-11/12 mx-auto py-5">
                  <h3 class="text-center text-3xl font-semibold mb-5">{{data.product2.p5.title}}</h3>
                   <div class="line-cus mb-5"></div>  
                  <p class="mb-8" v-html="data.product2.p5.content"></p> 
              </div>

                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product2.p6.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-2 lg:w-7/12 mx-auto">
                        <div v-for="(value, idx) in data.product2.p6.list" :key="idx" class="flex mb-3">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-7 self-start">
                            <p class="font-semibold">{{value}}</p>
                        </div>
                    </div>    
                </div>
            </div>    
        </div>

      <!-- Decorative system -->
        <div :ref="data.product3.linkId" class="py-2">
          <div @click="updateStatus(3)">
              <title-product :data="data.product3.headline" class="cursor-pointer"></title-product>
            </div>
          <div class="w-11/12 mx-auto bg-white py-4 mb-10" v-if="data.product3.status">
              <div class="w-11/12 mx-auto py-7">
                  <h3 class="text-center text-3xl font-semibold mb-5">{{data.product3.p1.title}}</h3>
                   <div class="line-cus mb-5"></div>  
                  <p class="mb-8" v-html="data.product3.p1.content"></p>
                  <div class="bg-cyan w-4/12 mx-auto h-0.5 mb-10"></div>
                  <div class="grid grid-cols-2 lg:w-7/12 mx-auto">
                        <div v-for="(value, idx) in data.product3.p1.list" :key="idx" class="flex">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-6 self-start">
                            <p>{{value}}</p>  
                        </div>
                  </div>
              </div>


                <div class="w-11/12 mx-auto py-7">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product3.p2.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-2 lg:w-7/12 mx-auto">
                        <div v-for="(value, idx) in data.product3.p2.list" :key="idx" class="flex mb-3">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-6">
                            <p class="font-semibold">{{value}}</p>
                        </div>
                    </div>

                    <div class="flex justify-center mt-10">
                      <router-link to="/contact">
                        <span class="border-cyan border-2 px-5 py-1.5 rounded-full inline-block text-center">
                            For more detail and spesific product, please contact us
                        </span>
                      </router-link>
                    </div>      
                </div>

            </div>    
        </div>

        <!-- Anti Corrosion -->
        <div :ref="data.product4.linkId" class="py-2">
          <div @click="updateStatus(4)">
              <title-product :data="data.product4.headline" class="cursor-pointer"></title-product>
            </div>

            <div class="w-11/12 mx-auto bg-white py-4 mb-10" v-if="data.product4.status">
              <div class="w-11/12 mx-auto py-6">
                  <h3 class="text-center text-3xl font-semibold mb-5">{{data.product4.p1.title}}</h3>
                   <div class="line-cus mb-5"></div>  
                  <div class="grid grid-cols-1 lg:grid-cols-2">
                      <p class="mb-8 mr-10" v-html="data.product4.p1.content"></p>
                      <img :src="data.product4.p1.image" alt="" class="w-9/12 m-auto">
                  </div>
              </div>

                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product4.p2.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-2 lg:w-7/12 mx-auto">
                        <div v-for="(value, idx) in data.product4.p2.list" :key="idx" class="flex mb-3">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-7 self-start">
                            <p class="font-semibold">{{value}}</p>
                        </div>
                    </div>    
                </div>
            </div>        
        </div>

        <!-- Electroless Nickel-->
        <div :ref="data.product5.linkId" class="py-2">
          <div @click="updateStatus(5)">
              <title-product :data="data.product5.headline" class="cursor-pointer"></title-product>
            </div>

            <div class="w-11/12 mx-auto bg-white py-4 mb-10" v-if="data.product5.status">
              <div class="w-11/12 mx-auto py-6">
                  <h3 class="text-center text-3xl font-semibold mb-5">{{data.product5.p1.title}}</h3>
                   <div class="line-cus mb-5"></div>  
                  <div class="grid grid-cols-1 lg:grid-cols-2 mb-8">
                      <p class="mb-8 mr-10" v-html="data.product5.p1.content"></p>
                      <img :src="data.product5.p1.image" alt="" class="w-9/12 m-auto">
                  </div>
                    <div class="bg-cyan w-4/12 mx-auto h-0.5 mb-5"></div>
                  <div class="grid grid-cols-2 lg:w-2/4 mx-auto">
                        <div v-for="(value, idx) in data.product5.p1.list" :key="idx" class="flex">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-6">
                            <p>{{value}}</p>  
                        </div>
                  </div>  

              </div>

                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product5.p2.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-2 lg:w-2/4 mx-auto">
                        <div v-for="(value, idx) in data.product5.p2.list" :key="idx" class="flex mb-3">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-7">
                            <p class="font-semibold">{{value}}</p>
                        </div>
                    </div>

                    <div class="flex justify-center mt-10">
                      <router-link to="/contact">
                        <span class="border-cyan border-2 px-5 py-1.5 rounded-full inline-block text-center">
                            For more detail and spesific product, please contact us
                        </span>
                      </router-link>
                    </div> 

                </div>
            </div>        
        </div>

      <!-- Electroplating Basic Chemicals -->
        <div :ref="data.product6.linkId" class="py-2">
          <div @click="updateStatus(6)">
              <title-product :data="data.product6.headline" class="cursor-pointer"></title-product>
            </div>

            <div class="w-11/12 mx-auto bg-white py-4 mb-10" v-if="data.product6.status">

                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product6.p1.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-auto gap-10">
                        <div v-for="(value, idx) in data.product6.p1.list" :key="idx" class="flex mb-3 flex-col">
                            <div class="flex mb-2">
                                <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-7">
                                <p class="font-semibold text-lg">{{value.title}}</p>
                            </div>
                            <div class="bg-cyan w-2/4 h-0.5 ml-3 mb-4"></div>
                            <div class="space-y-3 ml-1">
                                <div v-for="(item, index) in value.list" :key="index" class="flex">
                                     <img src="@/assets/products/dot.svg" alt="" class="flex-shrink-0 self-start w-5 mt-1">
                                    <p>{{item}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center mt-5">
                      <router-link to="/contact">
                        <span class="border-cyan border-2 px-5 py-1.5 rounded-full inline-block text-center">
                            For more detail and spesific product, please contact us
                        </span>
                      </router-link>
                    </div> 

                </div>
            </div>        
         </div>


      <!-- Electroplating Chemical Laboratory Reagents  -->
        <div :ref="data.product7.linkId" class="py-2">
          <div @click="updateStatus(7)">
              <title-product :data="data.product7.headline" class="cursor-pointer"></title-product>
            </div>

            <div class="w-11/12 mx-auto bg-white py-4 mb-10" v-if="data.product7.status">

                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product7.p1.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-2 lg:w-2/4 mx-auto">
                        <div v-for="(value, idx) in data.product7.p1.list" :key="idx" class="flex mb-5">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-7 self-start">
                            <p class="text-base font-semibold">{{value}}</p>  
                        </div>
                  </div>  

                    <div class="flex justify-center mt-5">
                      <router-link to="/contact">
                        <span class="border-cyan border-2 px-5 py-1.5 rounded-full inline-block text-center">
                            For more detail and spesific product, please contact us
                        </span>
                      </router-link>
                    </div> 

                </div>
            </div>        
        </div>

      <!-- Electroplating Equipments -->
        <div :ref="data.product8.linkId" class="py-2">
          <div @click="updateStatus(8)">
              <title-product :data="data.product8.headline" class="cursor-pointer"></title-product>
            </div>

            <div class="w-11/12 bg-white mx-auto py-4 mb-10" v-if="data.product8.status">

                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.product8.p1.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:w-2/4 mx-auto">
                        <div v-for="(value, idx) in data.product8.p1.list" :key="idx" class="flex mb-5">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-7 self-start">
                            <p class="text-base font-semibold">{{value}}</p>  
                        </div>
                    </div> 

                    <div class="grid grid-cols-2 md:w-3/4 lg:w-2/4 mx-auto gap-16">
                            <img :src="value" alt="" class="flex-shrink-0 self-start" v-for="(value, idx) in data.product8.p1.images" :key="idx" >
                    </div>   

                    <div class="flex justify-center mt-10">
                      <router-link to="/contact">
                        <span class="border-cyan border-2 px-5 py-1.5 rounded-full inline-block text-center">
                            For more detail and spesific product, please contact us
                        </span>
                      </router-link>
                    </div> 

                </div>
            </div>        
        </div>      

              <!-- Our Product -->
        <div :ref="data.ourProduct.linkId" class="py-2 mt-10">
          <div>
                <div :style="data.ourProduct.headline.css" class="w-full h-56 flex justify-center bg-cover bg-center">
                    <div class="flex flex-col justify-center items-center w-11/12">
                        <h1 class="text-3xl lg:text-4xl text-white font-semibold">{{data.ourProduct.headline.title}}</h1>         
                    </div>
                </div>              
            </div>

            <div class="w-11/12 bg-white mx-auto py-4 mb-10">
                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.ourProduct.p1.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-1 lg:w- w-3/5 mx-auto">
                        <div v-for="(value, idx) in data.ourProduct.p1.list" :key="idx" class="flex mb-5">
                            <img src="@/assets/products/check.svg" alt="" class="flex-shrink-0 w-6 mr-3 self-start">
                            <p class="text-base font-semibold" v-html="value"></p>  
                        </div>
                    </div> 
                </div>

                <div class="w-11/12 mx-auto py-5">
                    <h3 class="text-center text-3xl font-semibold mb-5">{{data.ourProduct.p2.title}}</h3>
                    <div class="line-cus mb-5"></div>  
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:w-2/4 mx-auto">
                        <div v-for="(value, idx) in data.ourProduct.p2.list" :key="idx" class="flex mb-5">
                            <img src="@/assets/products/list.svg" alt="" class="flex-shrink-0 w-7 self-start">
                            <p class="text-base font-semibold">{{value}}</p>  
                        </div>
                    </div> 
                </div>
            </div> 
        </div> 

  </section>
</template>

<script>
import TitleProduct from './TitleProduct.vue'
import product from '@/data/product.js'
export default {
  components: { TitleProduct },
  data() {
      return {
          data: product,
      }
  },
  methods: {
      updateStatus(value) {
          this.data['product' + value ].status = !this.data['product' + value ].status
      },
      goto(refName) {
          let getElement = this.$refs[refName];
          
          if(typeof getElement !== 'undefined' || getElement) {
            let getElementOffsetTop = getElement.offsetTop;
            window.scrollTo(0, getElementOffsetTop) 
          }
      },
      runScroll(newRoute) {
            let section = newRoute.hash.replace("#", "");
            if(section) {
                this.goto(section)
            }
      }
  },
  mounted() {
      this.runScroll(this.$router.currentRoute)
      
  },
  watch: {
      $route(newRoute) {
          this.runScroll(newRoute)
      }
  }
}
</script>

<style lang="scss" scoped>
.line-cus {
    // background: rgb(255,255,255);
    background: rgb(234,238,243);
    background: linear-gradient(90deg, rgba(234,238,243,0) 0%, rgba(210,214,218,1) 25%, rgba(210,214,218,1) 75%, rgba(234,238,243,0) 100%);
    height: 1px;
}
</style>