<template>
  <section class="py-20">
      <div class="container mx-auto px-5">
          <div class="md:w-9/12 mx-auto">
            <h3 class="uppercase text-xl md:text-2xl font-medium tracking-wider">{{data.name}}</h3>
            <div class="bg-cyan h-1 w-20 mt-2 mb-5"></div>
            <h2 class="text-xl md:text-3xl font-semibold mb-8">{{data.title}}</h2>
            <div class="text-lg mb-16">{{data.content}}</div>
            <div class="md:flex lg:flex-row justify-between hidden">
                <div>
                    <h3 class="text-3xl mb-2">{{data.person}}</h3>
                    <p class="text-2xl font-light text-gray-400">Founder</p>
                </div>
                <img :src="data.sign" alt="" class="w-52">
            </div>
            <div class="flex flex-col lg:flex-row justify-between md:hidden">
                <div>
                    <h3 class="text-2xl mb-2">{{data.person}}</h3>
                    <p class="text-xl font-light text-gray-400">Founder</p>
                </div>
                <img :src="data.sign" alt="" class="w-52 self-end">
            </div>
          </div>
      </div>
  </section>
</template>

<script>
import about from '@/data/about.js'
export default {
    data() {
        return {
            data: about.founder
        }
    }
}
</script>

<style>

</style>