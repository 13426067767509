<template>
  <section class="container mx-auto px-5">
      <div class="flex flex-col lg:flex-row lg:items-center">
        <div class="flex-shrink-0 lg:mr-14 mx-auto mb-16 lg:mb-0">
            <img :src="data.image" alt="" class="w-72">
        </div>
        <div class="flex-grow">
            <h3 class="uppercase text-xl md:text-2xl font-medium tracking-wider">{{data.name()}}</h3>
            <div class="bg-cyan h-1 w-20 mt-2 mb-5"></div>
            <h1 class="text-xl md:text-3xl font-semibold mb-8">{{data.pt()}}</h1>
            <p v-html="data.content"></p>
        </div>
      </div>
  </section>
</template>

<script>
import about from '@/data/about.js'
export default {
    data() {
        return {
            data: about.aboutUs
        }
    }
}
</script>

<style>

</style>