<template>
  <section class="container mx-auto px-5">
      <div class="w-full xs:w-10/12 sm:w-7/12 md:w-11/12 lg:w-full xl:w-5/6 mx-auto">
        <h3 class="uppercase text-2xl font-medium tracking-wider text-center">{{data.name}}</h3>
        <div class="bg-cyan h-1 w-20 mt-2 mb-16 mx-auto"></div>
        <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            <div v-for="(value, idx) in data.list" :key="idx" :style="{'background-image': 'url(' + require('@/assets/about/vision-bg.svg') + ')'}" class="vision bg-no-repeat text-center bg-contain rounded-xl shadow-xl" >
                <h3 class="text-2xl font-medium uppercase py-6 mt-1 mb-14">{{value.title}}</h3>
                <div class="w-10/12 mx-auto mb-8 text-lg relative">
                    {{value.content}}
                    <div :style="{'background-image': 'url(' + require('@/assets/about/visionicon.svg') + ')'}" class="absolute h-20 w-20 bg-no-repeat bg-contain -top-2"></div>
                </div>
            </div>
        </div>
      </div>
  </section>
</template>

<script>
import about from '@/data/about.js'
export default {
    data() {
        return {
            data: about.visiMisi
        }
    }
}
</script>

<style lang="scss" scoped>
.vision {
    height: 320px;
}
</style>