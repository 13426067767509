<template>
  <div :style="data.css" class="w-full h-56 flex justify-center bg-cover bg-center">
      <div class="flex flex-col justify-center lg:flex-row lg:justify-between lg:items-center w-11/12">
        <h1 class="text-3xl lg:text-4xl text-white font-semibold">{{data.title}}</h1>
        <div class="flex mt-10 lg:mt-0 self-end lg:self-center flex-shrink-0">
            <span class="text-white text-2xl font-light flex-shrink-0">Read More</span>
            <img src="@/assets/products/arrow.svg" alt="" class="ml-4 flex-shrink-0">
        </div>          
      </div>
  </div>
</template>

<script>
export default {
 props: ['data'],   
 data() {
     return {
         image: {
             css: {
                backgroundImage: `url(${require("@/assets/products/cleaner.webp")})`
            }
         }
     }
 }
}
</script>

<style>

</style>