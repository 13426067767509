<template>
    <div>
        <top-banner :css="banner.css" :data="banner.data" class="mb-20"></top-banner>
        <div class="relative">
          <about-us class="mb-20"></about-us>
          <img src="@/assets/home/blue/2.webp" alt="" class="absolute w-20 top-24 lg:top-80 hidden md:block">
          <img src="@/assets/home/blue/3.webp" alt="" class="absolute w-24 right-0 top-0 ">
        </div>
        <vision-mission class="mb-20"></vision-mission>
        <div class="bg-grad2 relative">
            <founder></founder>
            <img src="@/assets/home/blue/4.webp" alt="" class="absolute w-24 -top-20 lg:top-auto lg:-mt-60">
            <img src="@/assets/home/blue/5.webp" alt="" class="absolute w-24 -mt-84 right-0">
            <div>
              <div class="line-cus container mx-auto"></div>
            </div>
            <our-client-all></our-client-all>
          </div>  
        
    </div>
</template>

<script>
import about from '@/data/about.js'
import TopBanner from '../components/all/TopBanner.vue'
import AboutUs from '../components/about/AboutUs.vue'
import VisionMission from '../components/about/VisionMission.vue'
import Founder from '../components/about/Founder.vue'
import OurClientAll from '../components/all/OurClientAll.vue'

export default {
  components: { TopBanner, AboutUs, VisionMission, Founder, OurClientAll },
    metaInfo: {
    title: 'About Us',
    titleTemplate: '%s - PT. Surya Langgeng Indonesia',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  },
  data() {
    return {
        banner: {
          css: {
            backgroundImage: about.banner.bg,
          },
          data: about.banner
      }
    }
  }  
}
</script>

<style lang="scss" scoped>
.line-cus {
    // background: rgb(255,255,255);
  background: rgb(234,238,243);
  background: linear-gradient(90deg, rgba(234,238,243,1) 0%, rgba(210,214,218,1) 25%, rgba(210,214,218,1) 75%, rgba(234,238,243,1) 100%);
  height: 2px;
}
  .bg-grad2 {
    background: rgb(243,244,245);
    background: linear-gradient(0deg, rgba(243,244,245,1) 0%, rgba(230,234,238,1) 100%);
  }
</style>