<template>
    <div>
        <top-banner :css="banner.css" :data="banner.data" class="mb-20"></top-banner>
       <div class="relative">
          <list-services class="mb-20"></list-services>
          <img src="@/assets/home/blue/3.webp" alt="" class="absolute w-24 right-0 -top-20 ">
       </div>
        <div class="relative">
          <our-client></our-client>
          <our-client-all class="hidden"></our-client-all>
            <img src="@/assets/home/blue/4.webp" alt="" class="absolute w-24 -top-20 lg:top-auto lg:-mt-60">
            <img src="@/assets/home/blue/5.webp" alt="" class="absolute w-24 top-0 right-0">
        </div>
    </div>
</template>

<script>
import services from '@/data/services.js'
import TopBanner from '../components/all/TopBanner.vue'
import ListServices from '../components/services/ListServices.vue'
import OurClient from '../components/services/OurClient.vue'
import OurClientAll from '../components/all/OurClientAll.vue'
export default {
  components: { TopBanner, ListServices, OurClient, OurClientAll },
    metaInfo: {
    title: 'Services',
    titleTemplate: '%s - PT. Surya Langgeng Indonesia',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  },
  data() {
    return {
        banner: {
          css: {
            backgroundImage: services.banner.bg,
          },
          data: services.banner
      }
    }
  }
}
</script>

<style>

</style>