<template>
  <div class="bg-gray2">
      <top-banner :css="banner.css" :data="banner.data" ></top-banner>
      <main-product></main-product>
      <contact-form class="pb-20"></contact-form>
  </div>
</template>

<script>
import product from '@/data/product.js'
import TopBanner from '../components/all/TopBanner.vue'
import MainProduct from '../components/products/MainProduct.vue'
import ContactForm from '../components/products/ContactForm.vue'
export default {
  components: { TopBanner, MainProduct, ContactForm },
    metaInfo: {
    title: 'Products & Application',
    titleTemplate: '%s - PT. Surya Langgeng Indonesia',
    htmlAttrs: {
      lang: 'en',
      amp: true
    }
  },
  data() {
      return {
          banner: {
          css: {
            backgroundImage: product.banner.bg,
          },
          data: product.banner
        }
      }
  }  
}
</script>

<style>

</style>