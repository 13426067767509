<template>
  <section :style="css" class="bg-height bg-cover flex">
      <div class="container mx-auto my-auto px-5">
          <div class="lg:w-4/5 xl:w-8/12 text-center lg:text-left">
            <h3 class="uppercase text-2xl tracking-wider text-white">{{data.name()}}</h3>
            <div class="bg-cyan h-0.5 w-20 mt-2 mb-5 mx-auto lg:mx-px"></div>
            <h1 class="text-white text-3xl  lg:text-5xl font-semibold lg:leading-snug mb-2">{{data.title}}</h1>
            <h2 class="text-2xl lg:text-3xl font-light lg:leading-snug text-white">{{data.content}}</h2>           
          </div>
      </div>
  </section>
</template>

<script>


export default {
  props: ['data', 'css'],
}
</script>

<style lang="scss">
  .bg-height {
    height: 600px;
  }
</style>